input[type="file"] {
  display: none;
}
.blue {
  display: block;
  position: relative;
  background-color: #025bee;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  padding: 0.5em 0;
  margin: auto;

  cursor: pointer;
}
