.ant-table-row:nth-of-type(even) {
  background-color: #f5f5f5 !important;
}
.column-custom {
  width: 120px;
}

.ant-table-tbody > tr > td {
  padding: 8px;
}
.addgrade {
  .ant-picker-clear {
    display: none;
  }
}
