ul,
li {
  list-style: none;
}
.note {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.note h6 {
  font-size: 20px;
  color: #001628;
}
.note p {
  font-size: 15px;
  color: #001628;
}
.note li a {
  text-decoration: none;
  color: #000;
  background: #ffc;
  display: block;
  height: 12em;
  width: 15em;
  padding: 1em;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);

  transition: transform 0.15s linear;
}

.note li:nth-child(even) a {
  position: relative;

  background: #50c878;
}
.note li:nth-child(3n) a {
  position: relative;

  background: #fe9f11;
}
.note li:nth-child(5n) a {
  position: relative;
}

.note li a:hover,
.note li a:focus {
  box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
  transform: scale(1.25);
  position: relative;
  z-index: 5;
}

.note li {
  margin: 1em;
}
