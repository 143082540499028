.layout-prof {
  margin: auto;
  width: 20%;
  float: left;
  background-color: #f5f5f5;
  border: 1px solid #f1eaea;
  height: 240px;
}
.layout-prof2 {
  display: block;
  width: 80%;
  float: right;
  padding-left: 5%;
}

.mode-view {
  padding-left: 3%;
}
@media only screen and (max-width: 900px) {
  .layout-prof {
    border: 1px solid #f1eaea;
    height: 240px;
    float: unset;
    padding-left: 0;
    width: 100%;
  }
  .layout-prof2 {
    display: block;
    float: unset;
    padding-left: 0%;
    width: 100%;
  }
  .mode-view {
    display: block;
  }
}
